import React from "react";
import Img from "gatsby-image";
import { get, has } from "lodash";

const Image = (params) => {

  const fileUrl = get( params.src, 'localFile.url');
  
  if(!fileUrl) {
    return;
  }
  
  const fileExt = fileUrl.split('.').pop();

  if(fileUrl && fileExt === "svg") {
    return(
      <img {...params} alt={params.alt} src={fileUrl} />
    );
  } else {

    if( has( params.src, 'localFile.childImageSharp.fluid') ) {
      return(
        <Img 
          {...params}
          fluid={params.src.localFile.childImageSharp.fluid} 
        />
      );
    } else {
      return(
        <Img 
          {...params}
          fixed={params.src.localFile.childImageSharp.fixed} 
        />
      );
    }
    
  }

}

export default Image;